import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby-link';
import { Billboard } from '@hagerty/react-components';
import { useTreatments } from '@splitsoftware/splitio-react';

import { Split, Treatment } from 'src/constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';
import { StackableCard } from 'src/components/stackable-card';
import { SiteDisclaimer } from 'src/components/Disclaimers';
import { MainFooter } from 'src/features/footer';

const meta = {
  title: 'Welcome to Hagerty Drivers Club',
  description:
    'There’s regular car life, then there’s Hagerty Drivers Club car life. By joining the Club, you’ve unlocked everything you need to take owning, driving and loving cars to the next level.',
};

const billboard = {
  category: 'Hagerty Drivers Club®',
  heading: 'Start. Your. Engines.',
  lead:
    'Hagerty Drivers Club was built for car lovers like you. It has everything you need to make owning, driving and loving cars better.',
};

const cards = [
  {
    buttons: [
      {
        buttonLabel: 'Start saving',
        buttonType: 'Secondary',
        href: `${process.env.HDC_HAGERTY_DRIVERS_CLUB_ROOT_URL}/partner-offers`,
      },
    ],
    headingText: '01. Exclusive automotive offers',
    leadText:
      'Take advantage of insider pricing on your favorite automotive brands and save on all the car stuff you already buy, like tires, parts and car care products.',
  },
  {
    buttons: [
      {
        buttonLabel: 'Email Ask Hagerty',
        buttonType: 'Secondary',
        href: 'mailto:askhagerty@hagerty.com',
      },
    ],
    headingText: '02. Expert support',
    leadText: (
      <>
        Dive into our market data with premium access to{' '}
        <a href={`${process.env.HAGERTY_ROOT_URL}/valuation-tools`}>Hagerty Valuation Tools®</a>, and get one-on-one
        advice from our Ask Hagerty help desk, available to answer all of your automotive questions, including:
      </>
    ),
    list: [
      'Locating parts and vehicles',
      'Finding the right maintenance and performance shops',
      'Car history and valuation guidance',
      'Pre-purchase inspection resources',
    ],
  },
  {
    buttons: [
      {
        buttonLabel: 'See the calendar',
        buttonType: 'Secondary',
        href: `${process.env.HDC_HAGERTY_DRIVERS_CLUB_ROOT_URL}/events/`,
      },
    ],
    headingText: '03. Members-only experiences',
    leadText: 'Get VIP access to in-person and digital experiences with the ultimate automotive enthusiast community.',
  },
  {
    buttons: [
      {
        buttonLabel: 'Read, watch, discuss',
        buttonType: 'Secondary',
        href: `${process.env.HAGERTY_ROOT_URL}/media`,
      },
    ],
    headingText: '04. Original content',
    leadText:
      'Keep pace with car culture with entertainment and how-to automotive content made for enthusiasts, by enthusiasts.',
  },
  {
    headingText: '05. Emergency services',
    leadText:
      '24/7 emergency roadside service, including guaranteed flatbed towing and premium support, keeps you on the road.',
    list: [
      'Unlimited emergency calls',
      'Live event truck tracking',
      'Text status updates',
      <>
        Dial <a href="tel:8883108020">888-310-8020</a> to request service
      </>,
    ],
  },
];

const InsuranceBenefits = () => {
  const splitIsReady = isReady();
  const treatments = useTreatments([Split.FeatureInsurancePages]);
  const hasInsurancePages = treatments[Split.FeatureInsurancePages].treatment === Treatment.On;

  useEffect(() => {
    if (splitIsReady && !hasInsurancePages) navigate('/');
  }, [splitIsReady]);

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <main>
        <Billboard
          categoryText={billboard.category}
          mainHeadingText={<h1 className="billboard__heading text-display_1 no-padding">{billboard.heading}</h1>}
          leadText={billboard.lead}
          video={{
            videoID: 'dzZH6rseZwk',
            videoPlatform: 'youtube',
          }}
        />
        <div className="container container_center">
          <div className="grid grid-wrap gutters_direct grid_align-center">
            <div className="col xs-size_1-of-1 max-width_content">
              <h2>As a member, you enjoy:</h2>
              {cards.map((card, index) => (
                <StackableCard
                  key={index}
                  buttons={card.buttons}
                  headingText={card.headingText}
                  leadText={card.leadText}
                  list={card.list}
                />
              ))}
              <SiteDisclaimer />
            </div>
          </div>
        </div>
        <MainFooter />
      </main>
    </>
  );
};

export default InsuranceBenefits;
